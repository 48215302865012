import { IUser, UserId } from '@chessclub/grpc_wrapper';
import { create } from 'zustand';
import { ServerApi } from '../../../transport/ServerApi';

export enum UserListMode {
  ADD = 'add',
  DISPLAY = 'display',
}

export interface UserListStore {
  mode: UserListMode;
  searchPlaceholder: string;
  persisted: IUser[];
  found: IUser[];
  contacts: IUser[];
  added: IUser[];
  removed: IUser[];
  requestText?: string;

  setRequestText(requestText: string): Promise<void>,

  init(ownerId: UserId, users: IUser[]): void;

  addMode(): void;
  displayMode(): void;

  doAdd(user: IUser): void;

  doDelete(user: IUser): void;
}

export const useUserListStore = create<UserListStore>((
  set,
  get,
) => {

  return {
    mode: UserListMode.DISPLAY,
    searchPlaceholder: '',
    persisted: [],
    found: [],
    contacts: [],
    added: [],
    removed: [],

    async setRequestText(requestText: string): Promise<void> {
      if (requestText.length >= 0 && requestText.length < 3) {
        const searchPlaceholder = requestText.length ? ' (Введите 3 и более символов)' : '';
        set({ requestText, found: [], searchPlaceholder });
      } else {
        set({ requestText });
        let found = await ServerApi.accessService.searchUsersByName(requestText, 15);
        const {persisted, added} = get();
        const exclude = [...(persisted || []), ...added].map(u => u.id)
        found = found.filter(u => !exclude.includes(u.id))
        set({ found });
      }
    },

    async init(ownerId: UserId, users: IUser[]): Promise<void> {
      const contacts = await ServerApi.contactsService.getContactsByUserId(ownerId);
      const contactsUsers = await ServerApi.accessService.getUsersByIds(contacts.map(c => c.contactId));
      set({
        persisted: users || [],
        mode: UserListMode.DISPLAY,
        contacts: contactsUsers,
        found: [],
        added: [],
        removed: [],
      });
    },

    addMode(): void {
      set({ mode: UserListMode.ADD });
    },


    displayMode(): void {
      set({ mode: UserListMode.DISPLAY });
    },


    doAdd(user: IUser) {
      if (get().removed.find(u => user.id === u.id)) {
        set({
          removed: get().removed.filter(u => user.id !== u.id),
        });
      } else {
        set({
          mode: UserListMode.DISPLAY,
          added: [...get().added, user],
        });
      }

      get().setRequestText('');
    },

    doDelete(user: IUser) {
      set({ removed: [...get().removed, user] });
    },

  };
});