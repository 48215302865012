import { IRoom } from '../../interfaces/room';
import { emitCentrifugeEvent, joinChannelIfAlreadyNot} from '@chessclub/realtime_infrastructure';
import { makeRoomChannelKey, RoomChannel } from '../../transport/messaging/RoomChannel';
import {create} from 'zustand';
import Centrifuge from 'centrifuge';
import { ServerApi } from '../../transport/ServerApi';
import { IUser, RoomId, UserId } from '@chessclub/grpc_wrapper';
import {useLessonStore} from "../lesson/LessonStore";
import { RoomsStoreActions, RoomsStoreState } from './RoomsStoreApi';
import { replaceById } from '../../helpers/replaceById';

type RoomsStore = RoomsStoreState & RoomsStoreActions

export const useRoomsStore = create<RoomsStore>((
  set,
  get
) => {

    let listenersMap = {};

    function countStudents(sub: Centrifuge.Subscription, roomId: RoomId){
        sub.presenceStats().then((stats) => {
            const items = get().items.map(item => {
                if (item.id === roomId) {
                    item.anyStudentPresents = stats.numClients > 1;
                    // console.log(stats.num_clients, 'stats.num_clients', roomId)
                }
                return item;
            });
            set({items})
        });
    }

    async function join(roomId: RoomId) {
        const {subscription: sub} = await joinChannelIfAlreadyNot(
          makeRoomChannelKey(roomId));
        const countFn = () => countStudents(sub, roomId);
        sub.addListener('join', countFn)
        sub.addListener('leave', countFn)
        listenersMap[roomId] = () => {
            sub.removeListener('join', countFn)
            sub.removeListener('leave', countFn)
            delete listenersMap[roomId];
        };
        countFn()
    }



    return {
        userId: null,
        editingRoom: null,
        items: [],

        async goOnline(roomId: RoomId) {
            const chKey = makeRoomChannelKey(roomId);
            const items = get().items;
            const room = items.find(room => room.id === roomId)
            room.isActive = true
            useLessonStore.getState().setActiveLesson(room);
            await join(roomId);
            await get().updateRoom(room);
            // await ServerApi.roomsService.updateRoom(room);
            await emitCentrifugeEvent(chKey, RoomChannel.ROOM_ONLINE_STATE_CHANGED, {
                isOnline: true,
            });
            set({ items });
        },
        async goOffline(roomId: RoomId) {
            const chKey = makeRoomChannelKey(roomId);
            const items = get().items;
            const room = items.find(room => room.id === roomId)
            room.isActive = false
            room.customData.demonstration = null;
            listenersMap[roomId] && listenersMap[roomId]();
            await ServerApi.roomsService.updateRoom(room);
            await emitCentrifugeEvent(chKey, RoomChannel.ROOM_ONLINE_STATE_CHANGED, {
                isOnline: false,
            });
            set({ items });
        },
        initRoomStore: async (userId: UserId) => {
            const classrooms = await ServerApi.roomsService.getRooms(userId);

            const ownersIds = [];
            classrooms.forEach(room => {
                !ownersIds.includes(room.userId) && ownersIds.push(room.userId)
            });
            const ownersProfiles = await ServerApi.accessService.getUsersByIds(ownersIds);


            const studentsIds = [];
            classrooms.forEach(room => {
                room.students.forEach(studentId => {
                    !studentsIds.includes(studentId) && studentsIds.push(studentId)
                })
            });

            const studentsProfiles = await ServerApi.accessService.getUsersByIds(studentsIds);

            const items = classrooms.map(r => ({
                ...r,
                studentsProfiles: r.students.map(studentId => studentsProfiles[studentsIds.indexOf(studentId)]),
                coach: ownersProfiles[ownersIds.indexOf(r.userId)].displayName
            }));

            await Promise.all(classrooms
              .filter(room => room.isActive)
              .map(async room => await join(room.id)));


            set({ items,  userId});
        },

        deleteRoom: async (id: RoomId) => {
            await ServerApi.roomsService.deleteRooms([id]);
            const items = get().items.filter((room) => room.id !== id);
            set({ items });
        },
        addRoom: async (room: IRoom) : Promise<RoomId>=> {
            const roomId = await ServerApi.roomsService.createRoom(room);
            set({ items: [...get().items, {...room, id: roomId}], });
            return roomId;
        },
        updateRoom: async (room: IRoom) => {
            await ServerApi.roomsService.updateRoom(room)
            set({ items: replaceById(get().items, room) });
        },

        async updateRoomUsers(id: RoomId, deleted: UserId[], added: IUser[]): Promise<void> {
            await ServerApi.roomsService.deleteRoomUsers(id, deleted)
            const addedIds = added.map(u => u.id);
            await ServerApi.roomsService.setRoomUsers(id, addedIds)
            const rooms = get().items.map(room => {
                if (room.id !== id)
                    return room
                let students = room.students.filter(u => !deleted.includes(u));
                students.push(...addedIds)
                let studentsProfiles = (room.studentsProfiles || []).filter(u => !deleted.includes(u.id));
                studentsProfiles.push(...added)
                return Object.assign({}, room, {students, studentsProfiles})
            })
            set({items: rooms})
        },
    } as RoomsStore;
});


