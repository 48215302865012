import {ChessclubUser} from '../../interfaces/user';
import {loggedUserFromGrpcUser} from '../../logic/loggedUserFromGrpcUser';
import {connectSocket} from '../../transport/Socket';
import {
  centrifugeInstance,
  createChannelKey,
  disconnectFromCentrifuge,
  unsubscribeFromChannel,
} from '@chessclub/realtime_infrastructure/index';
import {MediaService} from '@chessclub/video-conf';
import {makeRoomChannelKey} from '../../transport/messaging/RoomChannel';
import {ServerApi} from '../../transport/ServerApi';
import { useLessonStore } from '../lesson/LessonStore';
import { AccessService_checkAuth, AccessService_NoUser } from '@chessclub/grpc_wrapper/src/api/services/AccessServiceExceptions';

export async function checkAuth(): Promise<ChessclubUser|keyof typeof AccessService_checkAuth> {
  const response = await ServerApi.accessService.checkAuth();
  return typeof response === "string" ? response : loggedUserFromGrpcUser(response);
}

export async function connectWs(session: string, customHeader: string): Promise<string> {
  return new Promise((resolve) => {

    connectSocket(session, customHeader);

    const connect = () => {
      centrifugeInstance.socket.removeListener('connected', connect);
      resolve(centrifugeInstance.socketId);
    };
    centrifugeInstance.socket.addListener('connected', connect);

    const error = () => {
      centrifugeInstance.socket.removeListener('error', error);
      resolve(null);
    };

    centrifugeInstance.socket.addListener('error', error);

  });
}

export async function disconnectWs(roomId){
  // MediaService.removeStream();
  unsubscribeFromChannel(makeRoomChannelKey(roomId));
  // unsubscribeFromChannel(createChannelKey(`video_${roomId}`));
  disconnectFromCentrifuge();
  useLessonStore.getState().exitLesson(roomId)
}
