import React, { useEffect } from 'react';
import styled from 'styled-components';
import { IUser, UserId } from '@chessclub/grpc_wrapper';
import Input from '../../../components/low-level-components/Input';
import { JustText, SvgImage, KnownIcons } from '../../../components';
import { HidingScrollbar } from '../../../components/low-level-components/HidingScrollbar';
import { useUserListStore, UserListMode } from './UserListStore';
import { UserInList } from './UserInList';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export function UsersList(props: { ownerId: UserId; users: IUser[] }) {

  const { mode, init } = useUserListStore();

  useEffect(() => {
    init(props.ownerId, props.users);
  }, [props.ownerId, props.users]);

  return <Wrapper>
    {mode === UserListMode.DISPLAY ? <DisplayMode /> : <SearchMode />}
  </Wrapper>;
}

function DisplayMode() {
  const { addMode, persisted, added, doDelete } = useUserListStore();
  const users = [...(persisted || []), ...added];
  return <>
    <div style={{ display: 'grid', gridTemplateColumns: '1fr 35px', alignItems: 'center' }}>
      <JustText>Участники</JustText>
      <SvgImage icon={KnownIcons.plus} withHover onClick={addMode} />
    </div>
    <UserListOnly users={users} del={doDelete} />
  </>;
}

function SearchMode() {
  const { displayMode, doAdd, setRequestText, requestText, searchPlaceholder, found, contacts } = useUserListStore();
  const users = found.length ? found : contacts;
  return <>
    <Input
      label={'Поиск по сайту' + searchPlaceholder}
      value={requestText}
      onChange={(e) => setRequestText(e.target.value)}
      endAdornment={<SvgImage withHover style={{ paddingRight: 5 }} icon={KnownIcons.cross} onClick={displayMode} />}
    />
    <UserListOnly users={users} add={doAdd} />
  </>;
}

function UserListOnly({ users, add, del }: { users: IUser[]; add?; del?; }) {
  return <HidingScrollbar>
    <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
      {users.length ?
        users.map((u, i) => <UserInList key={i} user={u} />) :
        <JustText>Ничего не найдено</JustText>}
    </div>
  </HidingScrollbar>;
}
